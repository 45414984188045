import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "ui/theme";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import AppInit from "app/App/components/AppInit";
import "helpers/formats";
import reportWebVitals from "./reportWebVitals";
import store from "./rootStore";

import "ui/CountrySelect/freakflags.css";

import "moment/locale/nb";
import "moment/locale/en-gb";

import "dayjs/locale/nb";
import "dayjs/locale/en";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

const pathname = window.location.pathname.split("/");
let basename = pathname[1];
if (pathname[1] === "tickets" && pathname.length >= 2) {
    basename = `/tickets/${pathname[2]}`;
}

root.render(
    // <React.StrictMode>
    <BrowserRouter basename={basename}>
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <AppInit />
            </ThemeProvider>
        </Provider>
    </BrowserRouter>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
