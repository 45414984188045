import { routingType } from "types/configTypes";
import { registerRoutings } from "rootRouting";
import AsyncComponent from "ui/AsyncComponent";
interface IConfig {
    permissions: {
        update: boolean;
    };
    routing: Array<string>;
    url: string;
}

export const permissionKey = "location.setting";
export const url = "/settings";
export const urlTerms = `${url}/terms-and-conditions`;
export const urlPrivacyPolicy = `${url}/privacy-policy`;
export const urlBookingForm = `${url}/booking-form`;
export const urlOrganization = `${url}/organization`;
export const urlStyles = `${url}/styles`;

const getConfig = (permissions: Array<string>) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );

    const config: IConfig = {
        permissions: {
            update: false,
        },
        routing: [],
        url,
    };

    let routing: routingType = [];

    if (modulePermissions.indexOf(permissionKey + ".update") !== -1) {
        config.permissions.update = true;

        routing.push(
            {
                path: url,
                element: (
                    <AsyncComponent path="Settings/pages/SettingsIndexPage" />
                ),
            },
            {
                path: urlStyles,
                element: (
                    <AsyncComponent path="Settings/pages/StylesIndexPage" />
                ),
            },
            {
                path: urlTerms,
                element: (
                    <AsyncComponent path="Settings/pages/TermsIndexPage" />
                ),
            },
            {
                path: urlPrivacyPolicy,
                element: (
                    <AsyncComponent path="Settings/pages/PrivacyPolicyIndexPage" />
                ),
            },
            {
                path: urlBookingForm,
                element: (
                    <AsyncComponent path="Settings/pages/BookingFormIndexPage" />
                ),
            },
            {
                path: urlOrganization,
                element: (
                    <AsyncComponent path="Settings/pages/OrganizationSettingsIndexPage" />
                ),
            }
        );
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;
