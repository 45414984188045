import { IWebTicket, IWebUpsale } from "../interfaces";
import { webEventGet } from "../actions";
import WebEventLoader from "./WebEventLoader";

import {
    Box,
    Checkbox,
    Chip,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Icon,
    IconButton,
    Radio,
    RadioGroup,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { modalShow } from "app/App/actions";
import { UpsaleModel } from "app/Upsales/interfaces";
import useRequest from "api/useRequest";
import getSubtranslation, {
    getPublicSubtranslation,
} from "helpers/getSubtranslation";
import trans from "helpers/trans";
import { isEmpty } from "lodash";
import moment from "moment";
import { FC, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalContent, ModalTitle, RequestMessage } from "ui";

type ITicketWithQuantity = IWebTicket & {
    quantity: number;
};
interface ITicketSecondStep {
    uuid: string;
    guests: number;
    onSelectTicket: Function;
    onSelectUpsale: Function;
    children: React.ReactNode;
    onCloseModal: Function;
    bookingMessage: string;
    bookingStatus: number;
    selectedUpsales: any;
    onSelectMultiTicket: Function;
}

const TicketSecondStep: FC<ITicketSecondStep> = ({
    bookingMessage,
    bookingStatus,
    children,
    uuid,
    guests,
    onSelectTicket,
    onSelectUpsale,
    onCloseModal,
    selectedUpsales,
    onSelectMultiTicket,
}) => {
    const lang = document.documentElement.lang;
    const dispatch = useDispatch();

    const {
        "general-currency": currency,
        "general-max_tickets": maxTickets,
        "general-ticket_available_presentation": ticketAvailablePresentantion,
    } = useSelector((state: any) => state.web.settings.location_settings);

    const smallScreen = useMediaQuery((theme: any) =>
        theme.breakpoints.down("tablet")
    );
    const theme = useTheme();
    const [selectedTicket, setSelectedTicket] = useState<any>({});
    const [selectedMultiTickets, setSelectedMultiTickets] = useState<any>([]);
    const { data, isLoading, message, status, isError } = useRequest(
        webEventGet(uuid)
    );

    const preparedData = useMemo(() => {
        if (!data?.data) return null;
        const format = "YYYY-MM-DD HH:mm:ss";
        const utcTime = moment.utc(data.data.start_datetime, format);
        return {
            ...data.data,
            description: getSubtranslation(
                data.data.subtranslations.description
            ),
            title: getSubtranslation(data.data.subtranslations.title),
            location: getSubtranslation(data.data.subtranslations.location),
            localDateTime: moment(utcTime)
                .local()
                .locale(lang === "en" ? "en-gb" : lang)
                .format("dddd, DD.MM.YYYY, HH:mm"),
        };
    }, [data, lang]);

    useEffect(() => {
        if (preparedData?.ticket_multiselection) {
            onSelectMultiTicket(selectedMultiTickets);
        }
    }, [onSelectMultiTicket, preparedData, selectedMultiTickets]);

    const handleSelectTicket = (ticket: any) => {
        setSelectedTicket(ticket);
        onSelectTicket(ticket);
    };
    const handleSelectMultiTicket = (
        e: React.ChangeEvent<HTMLInputElement>,
        ticket: IWebTicket
    ) => {
        setSelectedMultiTickets((prev: ITicketWithQuantity[]) => {
            if (e.target.checked) {
                return [...prev, { ...ticket, quantity: 1 }];
            } else
                return prev.filter((item: IWebTicket) => item.id !== ticket.id);
        });
    };

    const handleSelectUpsale = (
        e: React.ChangeEvent<HTMLInputElement>,
        upsale: any
    ) => {
        onSelectUpsale(e.target.checked, { ...upsale, quantity: 1 });
    };

    const handleUpsaleAddQuantity = (upsaleId: number) => {
        const upsaleToUpdate = selectedUpsales.find(
            (item: UpsaleModel) => item.id === upsaleId
        );
        const upsaleQuantity = upsaleToUpdate?.quantity;
        onSelectUpsale(true, {
            ...upsaleToUpdate,
            quantity: upsaleQuantity + 1,
        });
    };

    const handleUpsaleRemoveQuantity = (upsaleId: number) => {
        const upsaleToUpdate = selectedUpsales.find(
            (item: UpsaleModel) => item.id === upsaleId
        );
        const upsaleQuantity = upsaleToUpdate?.quantity;
        if (upsaleQuantity === 1) onSelectUpsale(false, upsaleToUpdate);
        else
            onSelectUpsale(true, {
                ...upsaleToUpdate,
                quantity: upsaleQuantity - 1,
            });
    };

    const handleMultiTicketAddQuantity = (ticket: ITicketWithQuantity) => {
        setSelectedMultiTickets((prev: ITicketWithQuantity[]) =>
            prev.map((item: ITicketWithQuantity) =>
                item.id === ticket.id
                    ? { ...item, quantity: item.quantity + 1 }
                    : item
            )
        );
    };

    const handleMultiTicketRemoveQuantity = (ticket: ITicketWithQuantity) => {
        setSelectedMultiTickets((prev: ITicketWithQuantity[]) =>
            ticket.quantity === 1
                ? prev.filter((item) => item.id !== ticket.id)
                : prev.map((item) =>
                      item.id === ticket.id
                          ? { ...item, quantity: item.quantity - 1 }
                          : item
                  )
        );
    };

    const handleImageUpsale = (
        e: React.MouseEvent,
        id: number,
        image: string
    ) => {
        e.preventDefault();
        dispatch(modalShow("UpsaleImageModal", { id, image }));
    };

    useEffect(() => {
        if (
            preparedData &&
            isEmpty(selectedTicket) &&
            !preparedData.ticket_multiselection
        ) {
            handleSelectTicket(
                preparedData?.event_tickets.find(
                    (ticket: any) => guests <= ticket.current_available
                ) || {}
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preparedData]);

    useEffect(() => {
        if (preparedData && selectedTicket.current_available < guests) {
            handleSelectTicket(
                preparedData?.event_tickets.find(
                    (ticket: any) => guests <= ticket.current_available
                ) || {}
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [guests, preparedData]);

    const renderUpsalesQuantity = (upsaleId: number) => {
        const isSelected = selectedUpsales.find(
            (item: UpsaleModel) => item.id === upsaleId
        );
        if (!isSelected) return null;
        const quantity = selectedUpsales.find(
            (upsale: UpsaleModel) => upsale.id === upsaleId
        )?.quantity;
        return (
            <Box
                onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                }}
                sx={{
                    mt: 1,
                }}
            >
                <Box display="flex" justifyContent="start" alignItems="center">
                    <IconButton
                        onClick={() => handleUpsaleRemoveQuantity(upsaleId)}
                        sx={{
                            ":hover": {
                                boxShadow: "none",
                            },
                            width: { xs: 24, tablet: 28 },
                            height: { xs: 24, tablet: 28 },
                            "&.Mui-disabled": {
                                backgroundColor: "rgb(185 121 100 / 0.26)",
                            },
                        }}
                    >
                        <Icon sx={{ fontSize: { xs: 16, tablet: 18 } }}>
                            remove
                        </Icon>
                    </IconButton>
                    <Typography
                        sx={{
                            fontSize: { xs: 14, tablet: 16 },
                            fontWeight: 500,
                            px: 2,
                            fontFamily: "'Noto Sans', sans-serif",
                            color: {
                                xs: "#495366",
                                tablet: "#02263A",
                            },
                            width: "auto !important",
                            minWidth: 55,
                            textAlign: "center",
                        }}
                    >
                        {quantity}
                    </Typography>

                    <IconButton
                        onClick={() => handleUpsaleAddQuantity(upsaleId)}
                        sx={{
                            ":hover": {
                                boxShadow: "none",
                            },
                            width: { xs: 24, tablet: 28 },
                            height: { xs: 24, tablet: 28 },
                            "&.Mui-disabled": {
                                backgroundColor: "rgb(185 121 100 / 0.26)",
                            },
                        }}
                    >
                        <Icon sx={{ fontSize: { xs: 16, tablet: 18 } }}>
                            add
                        </Icon>
                    </IconButton>
                </Box>
            </Box>
        );
    };

    const renderTicketQuantity = (ticket: ITicketWithQuantity) => {
        const quantity = ticket.quantity;
        return (
            <Box
                onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                }}
            >
                <Box display="flex" justifyContent="start" alignItems="center">
                    <IconButton
                        onClick={() => handleMultiTicketRemoveQuantity(ticket)}
                        sx={{
                            ":hover": {
                                boxShadow: "none",
                            },
                            width: { xs: 24, tablet: 28 },
                            height: { xs: 24, tablet: 28 },
                            "&.Mui-disabled": {
                                backgroundColor: "rgb(185 121 100 / 0.26)",
                            },
                        }}
                    >
                        <Icon sx={{ fontSize: { xs: 16, tablet: 18 } }}>
                            remove
                        </Icon>
                    </IconButton>
                    <Typography
                        sx={{
                            fontSize: { xs: 14, tablet: 16 },
                            fontWeight: 500,
                            px: 2,
                            fontFamily: "'Noto Sans', sans-serif",
                            color: {
                                xs: "#495366",
                                tablet: "#02263A",
                            },
                            width: "auto !important",
                            minWidth: 55,
                            textAlign: "center",
                        }}
                    >
                        {quantity}
                    </Typography>

                    <IconButton
                        onClick={() => handleMultiTicketAddQuantity(ticket)}
                        disabled={
                            quantity === maxTickets ||
                            quantity === ticket.current_available
                        }
                        sx={{
                            ":hover": {
                                boxShadow: "none",
                            },
                            width: { xs: 24, tablet: 28 },
                            height: { xs: 24, tablet: 28 },
                        }}
                    >
                        <Icon sx={{ fontSize: { xs: 16, tablet: 18 } }}>
                            add
                        </Icon>
                    </IconButton>
                </Box>
            </Box>
        );
    };

    const renderTicketAvailable = (ticket: any, selectedQuantity: number) => {
        const chipData = {
            backgroundColor: "#D9F0E4",
            color: "#054808",
            iconColor: "#007D39!important",
            text: trans("event.webEvent.modal.ticketModal.available", {
                available: ticket.current_available,
            }),
        };

        // global settings
        if (preparedData.event_availability_presentations.length === 0) {
            if (ticketAvailablePresentantion === -1) {
                return null;
            }

            if (
                ticketAvailablePresentantion > 0 &&
                ticket.current_available >= ticketAvailablePresentantion
            ) {
                return null;
            }

            if (ticket.current_available === 0) {
                chipData.text = trans(
                    "event.webEvent.modal.ticketModal.soldOut"
                );
            }

            if (
                selectedQuantity > ticket.current_available ||
                ticket.current_available === 0
            ) {
                chipData.backgroundColor = "#FFC3BB66";
                chipData.color = "#650000";
                chipData.iconColor = "#970000!important";
            }
        } else {
            if (
                preparedData.event_availability_presentations.length > 1 ||
                preparedData.event_availability_presentations[0].value > 0
            ) {
                let found = false;
                for (const value of (
                    preparedData.event_availability_presentations ?? []
                ).sort((a: any, b: any) => a.value - b.value)) {
                    if (value.value > ticket.current_available) {
                        chipData.backgroundColor = value.color;
                        chipData.color = theme.palette.getContrastText(
                            value.color
                        );
                        chipData.iconColor = chipData.color + "!important";
                        chipData.text =
                            getPublicSubtranslation(
                                value.subtranslations.text,
                                { available: ticket.current_available }
                            ) ?? chipData.text;
                        found = true;
                        break;
                    }
                }

                if (!found) {
                    return null;
                }
            } else if (
                preparedData.event_availability_presentations[0].value === 0
            ) {
                chipData.backgroundColor =
                    preparedData.event_availability_presentations[0].color;
                chipData.color = theme.palette.getContrastText(
                    preparedData.event_availability_presentations[0].color
                );
                chipData.iconColor = chipData.color + "!important";
                chipData.text =
                    getPublicSubtranslation(
                        preparedData.event_availability_presentations[0]
                            .subtranslations.text,
                        { available: ticket.current_available }
                    ) ?? chipData.text;
            } else {
                return null;
            }
        }

        return (
            <Chip
                sx={{
                    height: 28,
                    minWidth: "fit-content",
                    fontFamily: "'Noto Sans'",
                    fontWeight: 500,
                    fontSize: 12,
                    backgroundColor: chipData.backgroundColor,
                    color: chipData.color,
                }}
                icon={
                    <Icon
                        sx={{
                            ml: "8px !important",
                            fontSize: 20,
                            color: chipData.iconColor,
                        }}
                    >
                        {selectedQuantity > ticket.current_available ||
                        ticket.current_available === 0
                            ? "highlight_off"
                            : "check_circle_outline"}
                    </Icon>
                }
                label={chipData.text}
            />
        );
    };

    const renderTicketList = () => {
        if (preparedData.ticket_multiselection) {
            return (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                    }}
                >
                    {preparedData.event_tickets.map((ticket: any) => {
                        const selected = selectedMultiTickets.find(
                            (item: ITicketWithQuantity) => item.id === ticket.id
                        );
                        const selectedQuantity = selected?.quantity || 0;
                        return (
                            <FormControlLabel
                                key={`ticket-${ticket.id}`}
                                value={ticket.id}
                                control={
                                    <Checkbox
                                        onChange={(e) =>
                                            handleSelectMultiTicket(e, ticket)
                                        }
                                        checked={Boolean(selected)}
                                        size="small"
                                        disableRipple
                                        disabled={
                                            guests > ticket.current_available ||
                                            ticket.current_available === 0
                                        }
                                        sx={{
                                            pt: "2px",
                                        }}
                                    />
                                }
                                sx={{
                                    mr: 0,
                                    color: "#495366",
                                    alignItems: "flex-start",
                                    ".MuiTypography-root": {
                                        width: "100%",
                                        fontSize: {
                                            xs: 14,
                                            tablet: 16,
                                        },
                                    },
                                }}
                                label={
                                    <Box>
                                        <Box
                                            onClick={(e) => e.preventDefault()}
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                alignItems: "flex-start",
                                                gap: 1.5,
                                                rowGap: 1,
                                                width: "100%",
                                                fontFamily:
                                                    "'Noto Sans', sans-serif",
                                                fontSize: {
                                                    xs: 14,
                                                    tablet: 16,
                                                },
                                                fontWeight: 500,
                                                cursor: "default",
                                            }}
                                        >
                                            <Box
                                                onClick={(e) =>
                                                    e.stopPropagation()
                                                }
                                                sx={{
                                                    wordBreak: "break-word",
                                                    color: {
                                                        xs: "#495366",
                                                        tablet: "#02263A",
                                                    },
                                                    width: "fit-content",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                {
                                                    ticket.subtranslations
                                                        ?.name[`lang_${lang}`]
                                                }
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "end",
                                                    color: {
                                                        xs: "#495366",
                                                        tablet: "#02263A",
                                                    },
                                                    ml: 1,
                                                    whiteSpace: "nowrap",
                                                    cursor: "default",
                                                }}
                                            >
                                                {ticket.price.price(
                                                    currency,
                                                    "left"
                                                )}
                                            </Box>
                                        </Box>
                                        <Box
                                            onClick={(e) => e.preventDefault()}
                                            sx={{
                                                minWidth: "132px",
                                                mt: 1,
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: 2,
                                                flexWrap: "wrap",
                                                alignItems: "center",
                                                cursor: "default",
                                            }}
                                        >
                                            {renderTicketAvailable(
                                                ticket,
                                                selectedQuantity
                                            )}
                                            {selected &&
                                                renderTicketQuantity(selected)}
                                        </Box>
                                    </Box>
                                }
                            />
                        );
                    })}
                </Box>
            );
        } else
            return (
                <RadioGroup sx={{ gap: 2 }}>
                    {preparedData.event_tickets.map((ticket: any) => {
                        return (
                            <FormControlLabel
                                key={`ticket-${ticket.id}`}
                                value={ticket.id}
                                control={
                                    <Radio
                                        size="small"
                                        checked={
                                            selectedTicket.id === ticket.id
                                        }
                                        disabled={
                                            guests > ticket.current_available ||
                                            ticket.current_available === 0
                                        }
                                        value={ticket.id}
                                        disableRipple
                                        onChange={(e) =>
                                            handleSelectTicket(ticket)
                                        }
                                        sx={{
                                            pt: "2px",
                                        }}
                                    />
                                }
                                sx={{
                                    mr: 0,
                                    color: "#495366",
                                    alignItems: "flex-start",
                                    ".MuiTypography-root": {
                                        width: "100%",
                                        fontSize: {
                                            xs: 14,
                                            tablet: 16,
                                        },
                                    },
                                }}
                                label={
                                    <Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                // flexWrap: "wrap",
                                                justifyContent: "space-between",
                                                alignItems: "flex-start",
                                                gap: 1.5,
                                                rowGap: 1,
                                                width: "100%",
                                                // flex: 1,
                                                fontFamily:
                                                    "'Noto Sans', sans-serif",
                                                fontSize: {
                                                    xs: 14,
                                                    tablet: 16,
                                                },
                                                fontWeight: 500,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    wordBreak: "break-word",
                                                    color: {
                                                        xs: "#495366",
                                                        tablet: "#02263A",
                                                    },
                                                    flex: 1,
                                                    // minWidth: "120px",
                                                }}
                                            >
                                                {
                                                    ticket.subtranslations
                                                        ?.name[`lang_${lang}`]
                                                }
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    // flex: 1,
                                                    justifyContent: "end",
                                                    color: {
                                                        xs: "#495366",
                                                        tablet: "#02263A",
                                                    },
                                                    ml: 1,
                                                    whiteSpace: "nowrap",
                                                }}
                                            >
                                                {ticket.price.price(
                                                    currency,
                                                    "left"
                                                )}
                                            </Box>
                                        </Box>
                                        <Box
                                            sx={{
                                                minWidth: "132px",
                                                mt: 1,
                                            }}
                                        >
                                            {renderTicketAvailable(
                                                ticket,
                                                guests
                                            )}
                                        </Box>
                                    </Box>
                                }
                            />
                        );
                    })}
                </RadioGroup>
            );
    };

    if (isLoading)
        return (
            <Box position="relative" sx={{ height: "100%" }}>
                <WebEventLoader />
            </Box>
        );

    if (isError) {
        return (
            <>
                {" "}
                <ModalTitle
                    onClose={() => onCloseModal()}
                    sx={{
                        pl: 2,
                        pt: 1,
                        pr: 1,
                        pb: { xs: 2, tablet: 0 },
                        ".MuiTypography-root": {
                            color: "#495366",
                            fontFamily: "'Lato', sans-serif",
                            fontSize: 18,
                            fontWeight: 700,
                        },
                    }}
                />
                <ModalContent
                    sx={{
                        pt: 0,
                        px: { xs: 2, tablet: 4 },
                        display: "flex",
                        flexDirection: "column",
                        pb: 0,
                    }}
                >
                    <RequestMessage
                        message={message}
                        status={status}
                        sx={{
                            width: "100% !important",
                            "&.MuiPaper-root": {
                                height: "fit-content",
                                mb: 2,
                            },
                        }}
                    />
                </ModalContent>
            </>
        );
    }

    if (!preparedData) return null;

    return (
        <>
            <ModalTitle
                onClose={() => onCloseModal()}
                sx={{
                    pl: 2,
                    pt: 1,
                    pr: 1,
                    pb: { xs: 1.5, tablet: 0 },
                    ".MuiTypography-root": {
                        color: "#495366",
                        fontFamily: "'Lato', sans-serif",
                        fontSize: 18,
                        fontWeight: 700,
                    },
                }}
                modalActions={
                    smallScreen &&
                    preparedData.age_limit && (
                        <Box
                            sx={{
                                display: "inline-flex",
                                justifyContent: "center",
                                alignItems: "start",
                                alignSelf: "start",
                                ml: "auto",
                            }}
                        >
                            <Box
                                sx={{
                                    background: "#fff",
                                    color: "#000",
                                    borderRadius: "50%",
                                    textAlign: "center",
                                    border: "2px solid red",
                                    height: 36,
                                    width: 36,
                                    margin: "2px",
                                    fontFamily: "'Noto Sans', sans-serif",
                                    fontWeight: 500,
                                    fontSize: 13,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {preparedData.age_limit}+
                            </Box>
                        </Box>
                    )
                }
            >
                {smallScreen && preparedData.title}
            </ModalTitle>
            <ModalContent
                sx={{
                    pt: 0,
                    px: { xs: 2, tablet: 4 },
                    display: "flex",
                    flexDirection: "column",
                    pb: 0,
                }}
            >
                <RequestMessage
                    message={bookingMessage}
                    status={bookingStatus}
                    sx={{
                        width: "100% !important",

                        "&.MuiPaper-root": {
                            height: "fit-content",
                            mb: 2,
                        },
                    }}
                />
                <Box>
                    <Typography
                        sx={{
                            fontFamily: "'Lato', sans-serif",
                            fontSize: { xs: 16, tablet: 20 },
                            color: { xs: "#495366", tablet: "#000" },
                            fontWeight: 700,
                            textAlign: "center",
                            mb: { xs: 1.5, tablet: 2.5 },
                        }}
                    >
                        {trans(
                            `event.webEvent.modal.ticketModal.title.chooseType`
                        )}
                    </Typography>

                    <Stack
                        divider={
                            <Divider
                                orientation="horizontal"
                                flexItem
                                sx={{ my: { xs: 2, tablet: 2.5 } }}
                            />
                        }
                    >
                        <Stack spacing={1.25}>
                            <Typography
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    fontFamily: "'Noto Sans', sans-serif",
                                    fontSize: { xs: 14, tablet: 16 },
                                    fontWeight: 500,
                                    color: { xs: "#495366", tablet: "#02263A" },
                                }}
                            >
                                <Icon
                                    sx={{
                                        fontSize: { xs: 12, tablet: 16 },
                                        color: "#407BFF",
                                        mr: 1,
                                    }}
                                >
                                    circle
                                </Icon>
                                {preparedData.localDateTime}
                            </Typography>
                            {!smallScreen && (
                                <Box
                                    sx={{
                                        fontFamily: "'Lato', sans-serif",
                                        fontSize: 18,
                                        color: "#02263A",
                                        fontWeight: 700,
                                        textAlign: "left",
                                        alignItems: "center",
                                        display: "flex",
                                    }}
                                >
                                    {preparedData.title}
                                    {!smallScreen && preparedData.age_limit && (
                                        <Box
                                            sx={{
                                                display: "inline-flex",
                                                justifyContent: "center",
                                                alignItems: "start",
                                                alignSelf: "start",
                                                ml: 1,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    background: "#fff",
                                                    color: "#000",
                                                    borderRadius: "50%",
                                                    textAlign: "center",
                                                    border: "2px solid red",
                                                    height: 36,
                                                    width: 36,
                                                    margin: "2px",
                                                    fontFamily:
                                                        "'Noto Sans', sans-serif",
                                                    fontWeight: 500,
                                                    fontSize: 13,
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                {preparedData.age_limit}+
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                            )}
                        </Stack>
                        {!preparedData.ticket_multiselection && children}
                    </Stack>
                </Box>
                <Box
                    sx={{
                        flex: 1,
                        minHeight: "90px",
                        overflowY: "auto",
                        overflowX: "hidden",
                        minWidth: "fit-content",
                        mt: { xs: 2, tablet: 2.5 },
                        p: 0,
                        gap: { xs: 2, tablet: 2.5 },
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <FormControl sx={{ width: "100%" }}>
                        <FormLabel
                            sx={{
                                fontFamily: "'Lato', sans-serif",
                                fontSize: { xs: 16, tablet: 18 },
                                fontWeight: 700,
                                textAlign: "left",
                                color: { xs: "#495366", tablet: "#02263A" },
                                mr: 0,
                                pb: 1,
                                "&.Mui-focused": { color: "black" },
                            }}
                        >
                            {trans(
                                `event.webEvent.modal.ticketModal.title.chooseType`
                            )}
                        </FormLabel>
                        {renderTicketList()}
                    </FormControl>
                    {preparedData.upsales.length > 0 && <Divider sx={{}} />}
                    {preparedData.upsales.length > 0 && (
                        <FormControl sx={{ width: "100%" }}>
                            <FormLabel
                                sx={{
                                    fontFamily: "'Lato', sans-serif",
                                    fontSize: { xs: 16, tablet: 18 },
                                    fontWeight: 700,
                                    textAlign: "left",
                                    pb: 1,
                                    color: { xs: "#495366", tablet: "#02263A" },
                                    mr: 0,
                                    "&.Mui-focused": { color: "black" },
                                }}
                            >
                                {trans(
                                    "event.webEvent.modal.ticketModal.title.upsales"
                                )}
                            </FormLabel>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 2,
                                }}
                            >
                                {preparedData.upsales.map((upsale: any) => {
                                    const selected = selectedUpsales.find(
                                        (item: IWebUpsale) =>
                                            item.id === upsale.id
                                    );
                                    return (
                                        <FormControlLabel
                                            key={`upsale-${upsale.id}`}
                                            value={upsale.id}
                                            control={
                                                <Checkbox
                                                    onChange={(e) =>
                                                        handleSelectUpsale(
                                                            e,
                                                            upsale
                                                        )
                                                    }
                                                    checked={Boolean(selected)}
                                                    size="small"
                                                    disableRipple
                                                />
                                            }
                                            sx={{
                                                // alignItems: "start",

                                                // cursor: "default",
                                                // width: "100%",
                                                mr: 0,
                                                color: "#495366",
                                                ".MuiTypography-root": {
                                                    width: "100%",
                                                },
                                            }}
                                            label={
                                                <Box>
                                                    <Box
                                                        // onClick={(e) => {
                                                        //     e.stopPropagation();
                                                        //     e.preventDefault();
                                                        // }}
                                                        sx={{
                                                            display: "flex",
                                                            flexDirection:
                                                                "row",
                                                            flexWrap: "nowrap",
                                                            justifyContent:
                                                                "space-between",
                                                            alignItems:
                                                                "center",
                                                            gap: 1,
                                                            width: "100%",
                                                            fontFamily:
                                                                "'Noto Sans', sans-serif",
                                                            fontSize: {
                                                                xs: 14,
                                                                tablet: 16,
                                                            },
                                                            fontWeight: 500,
                                                            color: {
                                                                xs: "#495366",
                                                                tablet: "#02263A",
                                                            },
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                color: {
                                                                    xs: "#495366",
                                                                    tablet: "#02263A",
                                                                },
                                                                flex: 1,
                                                                minWidth: 180,
                                                                wordBreak:
                                                                    "break-word",
                                                                display:
                                                                    "inline-flex",
                                                                gap: 1,
                                                                alignItems:
                                                                    "center",
                                                                cursor: "default",
                                                            }}
                                                            onClick={(e) =>
                                                                e.preventDefault()
                                                            }
                                                        >
                                                            {upsale.image && (
                                                                <Box
                                                                    component="img"
                                                                    src={
                                                                        upsale.image
                                                                    }
                                                                    alt={`upsale-${upsale.id}`}
                                                                    height={46}
                                                                    width={46}
                                                                    sx={{
                                                                        alignSelf:
                                                                            "flex-start",
                                                                        cursor: "pointer",
                                                                        objectFit:
                                                                            "contain",
                                                                    }}
                                                                    onClick={(
                                                                        e
                                                                    ) =>
                                                                        handleImageUpsale(
                                                                            e,
                                                                            upsale.id,
                                                                            upsale.image
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                            <Box
                                                                sx={{
                                                                    color: {
                                                                        xs: "#495366",
                                                                        tablet: "#02263A",
                                                                    },
                                                                    width: "fit-content",
                                                                    wordBreak:
                                                                        "break-word",
                                                                    display:
                                                                        "inline-flex",
                                                                    gap: 1,
                                                                    alignItems:
                                                                        "center",
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.stopPropagation();
                                                                }}
                                                            >
                                                                {
                                                                    upsale
                                                                        ?.subtranslations
                                                                        .name[
                                                                        `lang_${lang}`
                                                                    ]
                                                                }
                                                            </Box>
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                // flex: 1,
                                                                justifyContent:
                                                                    "end",
                                                                color: {
                                                                    xs: "#495366",
                                                                    tablet: "#02263A",
                                                                },
                                                                whiteSpace:
                                                                    "nowrap",
                                                            }}
                                                        >
                                                            {upsale.price.price(
                                                                currency,
                                                                "left"
                                                            )}
                                                        </Box>
                                                    </Box>

                                                    {renderUpsalesQuantity(
                                                        upsale.id
                                                    )}
                                                </Box>
                                            }
                                        />
                                    );
                                })}
                            </Box>
                        </FormControl>
                    )}
                </Box>
                <Divider sx={{ mt: 4 }} />
            </ModalContent>
        </>
    );
};
export default TicketSecondStep;
