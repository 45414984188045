import { useMediaQuery } from "@mui/material";
import { modalHide } from "app/App/actions";
import { generateParams } from "helpers/urlParams";
import { FC, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal } from "ui";
import WebEventDetailsComponent from "../components/WevEventDetailsComponent";

type EventDetailsType = {
    uuid: string;
    // content: Function;
    // title: string;
};
const EventDetailsModal: FC<EventDetailsType> = ({ uuid }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { filters, search } = useSelector((state: any) => state.web);
    const searchParams = useMemo(() => {
        const { queryParams } = generateParams({
            filters,
            search,
        });

        return queryParams;
    }, [filters, search]);

    const smallScreen = useMediaQuery((theme: any) =>
        theme.breakpoints.down("tablet")
    );
    const { "general-background_color": backgroundColor } = useSelector(
        (state: any) => state.web.settings.location_settings
    );

    const handleClose = () => {
        dispatch(modalHide("EventDetailsModal", { replace: true }));
        navigate(`/?${searchParams}`);
    };

    return (
        <Modal
            maxWidth="laptop"
            fullWidth
            open
            fullScreen={smallScreen}
            scroll="paper"
            sx={{
                ">.MuiPaper-root": {
                    background: backgroundColor,
                    color: (theme) =>
                        theme.palette.getContrastText(backgroundColor),
                },
                ".MuiDialogContent-root": {
                    px: 0,
                },
            }}
        >
            <WebEventDetailsComponent
                uuid={uuid}
                handleClosePopup={handleClose}
            />
        </Modal>
    );
};

export default EventDetailsModal;
