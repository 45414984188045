import { webEventGet } from "../actions";
import PageWrapper from "../components/PageWrapper";

import { Box, Typography } from "@mui/material";
import { modalHide, modalShow } from "app/App/actions";
import useRequest from "api/useRequest";
import trans from "helpers/trans";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import getSubtranslation from "helpers/getSubtranslation";
import { Button, ModalContent, ModalTitle, RequestMessage } from "ui";
import moment from "moment";
import { IWebEvent } from "../interfaces";
import { AxiosResponse } from "axios";

type WebEventDetailsComponentT = {
    handleClosePopup?: () => void;
    uuid: string;
};
const WebEventDetailsComponent: FC<WebEventDetailsComponentT> = ({
    handleClosePopup,
    uuid,
}) => {
    const dispatch = useDispatch();
    const { request, isError, status, message } = useRequest();

    const lang = document.documentElement.lang;
    const hasInit = useRef<boolean>(false);
    const [eventData, setEventData] = useState<any>(null);
    const {
        "general-background_color": backgroundColor,
        "general-button_background": buttonBackground,
        "general-event_details_presentation": eventPresentation,
    } = useSelector((state: any) => state.web.settings.location_settings);

    const preparedData = useMemo(() => {
        if (!eventData?.data) return null;

        return {
            ...eventData.data,
            buy: getSubtranslation(eventData.data.subtranslations.buy),
            description: getSubtranslation(
                eventData.data.subtranslations.description
            ),
            title: getSubtranslation(eventData.data.subtranslations.title),
            location: getSubtranslation(
                eventData.data.subtranslations.location
            ),
        };
    }, [eventData]);

    useEffect(() => {
        if (eventPresentation === "POPUP" && !hasInit.current && !eventData) {
            request(
                webEventGet(uuid, (response: AxiosResponse) => {
                    if (response.status === 200) {
                        setEventData(response.data);
                    }
                })
            );
            hasInit.current = true;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preparedData]);

    const noSell = useMemo(() => {
        if (!preparedData) return true;
        return [preparedData, ...preparedData.group_events].every(
            (item: IWebEvent) => item.no_sell
        );
    }, [preparedData]);

    const sellUrl = useMemo(() => {
        return preparedData && !noSell
            ? [preparedData, ...preparedData.group_events].every(
                  (item: IWebEvent) => item.sell_url
              )
            : false;
    }, [noSell, preparedData]);

    const isSoldOut = useMemo(() => {
        if (!preparedData || noSell || sellUrl) return false;
        return [preparedData, ...preparedData.group_events].every(
            (item: IWebEvent) =>
                item.event_tickets.every(
                    (ticket: any) => ticket.current_available <= 0
                )
        );
    }, [preparedData, noSell, sellUrl]);

    const handleCloseModal = () => {
        dispatch(modalHide("TicketModal"));
    };
    const handleBuyTickets = () => {
        if (sellUrl && preparedData.sell_url) {
            window.open(preparedData.sell_url, "_blank");
            return;
        }
        dispatch(
            modalShow("TicketModal", {
                onCloseModal: handleCloseModal,
                onCloseEventDetails:
                    eventPresentation === "POPUP"
                        ? () => dispatch(modalHide("EventDetailsModal"))
                        : undefined,
                uuid: preparedData.uuid,
            })
        );
    };

    const renderContent = () => {
        if (!preparedData) return null;

        if (eventPresentation === "POPUP") {
            return (
                <>
                    <ModalTitle
                        onClose={() => {
                            if (handleClosePopup) handleClosePopup();
                        }}
                    ></ModalTitle>

                    <ModalContent
                        sx={{
                            pt: 0,
                            px: 4,
                            display: "flex",
                            flexDirection: "column",
                            pb: 0,
                            gap: 2,
                        }}
                    >
                        {renderBody()}
                    </ModalContent>
                </>
            );
        }

        return renderBody();
    };

    const renderBody = () => {
        return (
            <>
                <PageWrapper
                    sx={{
                        height:
                            eventPresentation === "POPUP"
                                ? "fit-content"
                                : "100%",
                        pb: 0,
                    }}
                >
                    <Box
                        sx={{
                            height: { xs: 249, tablet: 365, laptop: 468 },
                            width: "100%",
                            position: "relative",
                        }}
                    >
                        {isSoldOut && (
                            <Box
                                sx={{
                                    zIndex: 100,
                                    width: "fit-content",
                                    position: "absolute",
                                    left: "50%",
                                    top: "50%",
                                    transform:
                                        "translate(-50%, -50%) rotate(45deg)",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    border: "4px solid red",
                                    px: 2,
                                }}
                            >
                                <Box
                                    sx={{
                                        color: "red",
                                        textAlign: "center",
                                        width: "100%",
                                        fontFamily: "'Noto Sans', sans-serif",
                                        fontWeight: 700,
                                        fontSize: 30,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        whiteSpace: "nowrap",
                                        textTransform: "uppercase",
                                    }}
                                >
                                    {trans(
                                        "event.webEvent.component.eventCard.soldOut"
                                    )}
                                </Box>
                            </Box>
                        )}
                        {preparedData.image ? (
                            <Box
                                component="img"
                                src={preparedData.image}
                                sx={{
                                    opacity: isSoldOut ? 0.4 : 1,
                                    objectFit: "cover",
                                    width: "100%",
                                    height: "100%",
                                }}
                            ></Box>
                        ) : (
                            <Box
                                sx={{
                                    width: "100%",
                                    height: "100%",
                                    opacity: isSoldOut ? 0.4 : 1,
                                    backgroundColor: (theme) =>
                                        theme.palette.getContrastText(
                                            backgroundColor
                                        ) === "#fff"
                                            ? "#fff"
                                            : backgroundColor,
                                    background: (theme) =>
                                        theme.palette.getContrastText(
                                            backgroundColor
                                        ) === "#fff"
                                            ? "linear-gradient(rgba(255,255,255, 0.9), rgba(0, 0, 0, 0.7))"
                                            : "linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7))",
                                }}
                            ></Box>
                        )}
                        {preparedData.age_limit && (
                            <Box
                                sx={{
                                    opacity: isSoldOut ? 0.4 : 1,
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    zIndex: 100,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Box
                                    sx={{
                                        background: "#fff",
                                        color: "#000",
                                        borderRadius: "50%",
                                        textAlign: "center",
                                        border: "2px solid red",
                                        height: 43,
                                        width: 43,
                                        margin: "8px",
                                        fontFamily: "'Noto Sans', sans-serif",
                                        fontWeight: 500,
                                        fontSize: 13,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    {preparedData.age_limit}+
                                </Box>
                            </Box>
                        )}
                        <Box
                            sx={{
                                position: "absolute",
                                bottom: 0,
                                right: 0,
                                width: "100%",
                                minHeight: { xs: "100%", laptop: "60%" },
                                // background:
                                //     preparedData.image && !isSoldOut
                                //         ? "linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7))"
                                //         : "transparent",
                            }}
                        >
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    gap: { xs: 4, tablet: 0.5 },
                                    display: "flex",
                                    flexDirection: {
                                        xs: "column-reverse",
                                        tablet: "column",
                                    },
                                    position: "absolute",
                                    bottom: 0,
                                    right: 0,
                                    pt: 3,
                                    pb: {
                                        xs: 2.5,
                                        tablet: 4,
                                        laptop: 9,
                                        desktop: 6,
                                    },
                                    width: { xs: "100%", tablet: "55%" },
                                    pr: {
                                        xs: 2,
                                        tablet: 6,
                                        laptop: 8,
                                        desktop: 12,
                                    },
                                    pl: { xs: 2, tablet: 0 },
                                }}
                            >
                                {noSell ? (
                                    <Box
                                        sx={{
                                            padding: "6px 16px",
                                            height: { xs: 40, tablet: 64 },
                                            display: "flex",
                                            width: {
                                                xs: 160,
                                                tablet: 248,
                                                laptop: 304,
                                                desktop: 427,
                                            },
                                            ml: { xs: "152px", xxs: "auto" },
                                        }}
                                    />
                                ) : (
                                    <Button
                                        onClick={handleBuyTickets}
                                        disabled={isSoldOut}
                                        sx={{
                                            ":hover": {
                                                background: buttonBackground,
                                                boxShadow: "none",
                                            },
                                            background: buttonBackground,
                                            textTransform: "uppercase",
                                            fontFamily: "'Lato', sans-serif",
                                            fontWeight: 700,
                                            fontSize: { xs: 16, tablet: 24 },
                                            borderRadius: 1,
                                            boxShadow: "none",
                                            padding: "6px 16px",
                                            height: { xs: 40, tablet: 64 },
                                            display: "flex",
                                            width: {
                                                xs: 160,
                                                tablet: 248,
                                                laptop: 304,
                                                desktop: 427,
                                            },
                                            ml: { xs: "152px", xxs: "auto" },
                                            color: (theme) =>
                                                theme.palette.getContrastText(
                                                    buttonBackground
                                                ),
                                        }}
                                    >
                                        {preparedData.buy}
                                    </Button>
                                )}
                                <Box
                                    sx={{
                                        width: "100%",
                                        alignItems: "flex-start",
                                        wordBreak: "break-word",
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 2,
                                        opacity: isSoldOut ? 0.4 : 1,
                                    }}
                                >
                                    {eventPresentation === "PAGE" && (
                                        <>
                                            <Typography
                                                sx={{
                                                    color: "white",
                                                    fontFamily:
                                                        "'Alumni Sans', sans-serif",
                                                    fontWeight: 700,
                                                    fontSize: {
                                                        xs: 32,
                                                        tablet: 64,
                                                    },
                                                    wordBreak: "break-word",
                                                    display: "-webkit-box",
                                                    " -webkit-line-clamp": 2,
                                                    lineClamp: 2,
                                                    " -webkit-box-orient":
                                                        "vertical",
                                                    overflow: "hidden",
                                                    lineHeight: 1,
                                                }}
                                                title={preparedData.title}
                                            >
                                                {preparedData.title}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    color: "white",
                                                    fontFamily:
                                                        "'Lato', sans-serif",
                                                    fontWeight: 700,
                                                    fontSize: {
                                                        xs: 14,
                                                        tablet: 20,
                                                    },
                                                    wordBreak: "break-word",
                                                }}
                                            >
                                                {preparedData.location}
                                            </Typography>
                                        </>
                                    )}
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    opacity: isSoldOut ? 0.4 : 1,
                                    paddingX: 1.5,
                                    alignItems: "center",
                                    position: "absolute",
                                    display: "flex",
                                    flexWrap: "wrap",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    left: 0,
                                    bottom: 0,
                                    color: "#FFF8F8",
                                    fontFamily: "'Noto Sans', sans-serif",
                                    fontWeight: { xs: 500, tablet: 600 },
                                    fontSize: { xs: 14, tablet: 16 },
                                    backgroundColor: "#000",
                                    zIndex: 10,
                                    width: 152,
                                    height: 66,
                                    border: {
                                        xs: "2px solid #FFA3A3",
                                        desktop: "2px solid #000",
                                    },
                                    boxShadow: {
                                        xs: "none",
                                        desktop: "-4px 4px 0px 0px #FFA3A3",
                                    },
                                }}
                            >
                                {renderDateTime()}
                            </Box>
                        </Box>
                    </Box>
                </PageWrapper>
                <Box
                    sx={{
                        paddingX: {
                            xs: 2,
                            tablet: 6,
                            // laptop: 8,
                            // desktop: 12,
                        },
                        paddingY: { xs: 6, tablet: 5 }, //, laptop: 10 },
                    }}
                >
                    <PageWrapper sx={{ height: "100%", pb: 0 }}>
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                gap: { xs: 2 },
                            }}
                        >
                            <Typography
                                sx={{
                                    color: (theme) =>
                                        theme.palette.getContrastText(
                                            backgroundColor
                                        ),
                                    fontFamily: "'Alumni Sans', sans-serif",
                                    fontWeight: 700,
                                    fontSize:
                                        eventPresentation === "PAGE"
                                            ? { xs: 32, tablet: 64 }
                                            : { xs: 32, tablet: 48 },
                                    wordBreak: "break-word",
                                    lineHeight: 1,
                                }}
                            >
                                {trans(
                                    "event.webEvent.page.webEventDetailsPage.welcome",
                                    { title: preparedData.title }
                                )}
                            </Typography>
                            <Typography
                                sx={{
                                    color: (theme) =>
                                        theme.palette.getContrastText(
                                            backgroundColor
                                        ),
                                    fontFamily: "'Lato', sans-serif",
                                    fontWeight: 700,
                                    fontSize: {
                                        xs: 14,
                                        tablet: 20,
                                    },
                                    wordBreak: "break-word",
                                }}
                            >
                                {preparedData.location}
                            </Typography>
                            <Box
                                sx={{
                                    color: (theme) =>
                                        theme.palette.getContrastText(
                                            backgroundColor
                                        ),
                                    fontFamily: "'Noto Sans', sans-serif",
                                    fontSize: { xs: 13, tablet: 16 },
                                    wordBreak: "break-word",
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: preparedData.description.replace(
                                        /\n/g,
                                        "<br/>"
                                    ),
                                }}
                            />
                        </Box>
                    </PageWrapper>
                </Box>
            </>
        );
    };
    const renderDateTime = () => {
        if (preparedData.group_events.length === 0) {
            return (
                <>
                    <Typography
                        sx={{
                            fontFamily: "'Noto Sans', sans-serif",
                            fontWeight: 600,
                            fontSize: 16,
                        }}
                    >
                        {" "}
                        {moment(preparedData.start_datetime)
                            .local()
                            .locale(lang === "en" ? "en-gb" : lang)
                            .format("ddd DD MMM")}
                    </Typography>
                </>
            );
        } else {
            const startDateTime = moment(preparedData.start_datetime)
                .local()
                .locale(lang === "en" ? "en-gb" : lang);

            const endDateTime = moment(
                preparedData.group_events[preparedData.group_events.length - 1]
                    .start_datetime
            )
                .local()
                .locale(lang === "en" ? "en-gb" : lang);
            const isSame = moment(startDateTime.format("YYYY")).isSame(
                endDateTime.format("YYYY")
            );

            return isSame ? (
                <>
                    <Typography
                        sx={{
                            fontFamily: "'Noto Sans', sans-serif",
                            fontWeight: 600,
                            fontSize: 16,
                        }}
                    >
                        {" "}
                        {startDateTime.format("DD.MM")}
                        {" - "}
                        {endDateTime.format("DD.MM")}
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: "'Noto Sans', sans-serif",
                            fontWeight: 600,
                            fontSize: 16,
                        }}
                    >
                        {endDateTime.format("YYYY")}
                    </Typography>
                </>
            ) : (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        position: "relative",
                        flexWrap: "wrap",
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: "'Noto Sans', sans-serif",
                            fontWeight: 600,
                            fontSize: 16,
                        }}
                    >
                        {" "}
                        {startDateTime.format("DD.MM.YYYY")}{" "}
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: "'Noto Sans', sans-serif",
                            fontWeight: 600,
                            fontSize: 16,
                        }}
                    >
                        - {endDateTime.format("DD.MM.YYYY")}
                    </Typography>
                </Box>
            );
        }
    };

    return (
        <>
            {isError && (
                <RequestMessage
                    status={status}
                    message={message}
                    sx={{ my: 2 }}
                />
            )}
            {renderContent()}
        </>
    );
};

export default WebEventDetailsComponent;
