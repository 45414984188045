import { LanguageModel } from "app/Languages/interfaces";
import { ISubtranslation } from "app/Settings/interfaces";
import store from "rootStore";

const getSubtranslation = (
    data: ISubtranslation | null,
    isCurrentLang?: boolean
) => {
    const state = store.getState();
    const app = state.web ? "web" : "app";
    const defaultLang = state[app].settings.languages.find(
        (lang: LanguageModel) => lang.is_default
    )?.key;
    const currentLanguage = state.app.lang;

    const languages = state[app].settings.languages;
    const lang = document.documentElement.lang;
    const languageToUse = isCurrentLang
        ? currentLanguage
        : languages.find((item: LanguageModel) => item.key === lang)?.key ||
          defaultLang;

    return data ? data[`lang_${languageToUse}` as keyof typeof data] : "";
};

export const getPublicSubtranslation = (
    data: ISubtranslation | null,
    tags?: { [key: string]: any }
) => {
    const state = store.getState();
    const defaultLang = state["web"].settings.languages.find(
        (lang: LanguageModel) => lang.is_default
    )?.key;
    // const currentLanguage = state.app.lang;
    const currentLanguage = document.documentElement.lang;

    let text = "";

    if (data) {
        if (data[`lang_${currentLanguage}` as keyof typeof data]) {
            text = data[
                `lang_${currentLanguage}` as keyof typeof data
            ] as string;
        } else {
            text = data[`lang_${defaultLang}` as keyof typeof data] as string;
        }
    }

    for (const i in tags) {
        text = text.replace(new RegExp(`:${i}`, "g"), tags[i]);
    }
    return text;
};

export default getSubtranslation;
