export const FETCH_SUCCESS = "WEB_EVENT/FETCH_SUCCESS";
export const FETCH_REQUEST = "WEB_EVENT/FETCH_REQUEST";
export const FETCH_FAILED = "WEB_EVENT/FETCH_FAILED";

export const TABLE_UPDATE = "WEB_EVENT/TABLE_UPDATE";
export const FILTERS_SUCCESS = "WEB_EVENT/FILTERS_SUCCESS";

export const SETTINGS_SUCCESS = "WEB_EVENT/SETTINGS_SUCCESS";
export const SETTINGS_FAILED = "WEB_EVENT/SETTINGS_FAILED";
export const SETTINGS_REQUEST = "WEB_EVENT/SETTINGS_REQUEST";
export const SCROLL = "WEB_EVENT/SCROLL";

export const AVAILABILITY_UPDATE = "AVAILABILITY_UPDATE";
export const GET_AVAILABILITY_SUCCESS = "AVAILABILITY/GET_SUCCESS";

const types = {
    GET_BOOKING_FAILED: "WEB_EVENT/BOOKING_GET_FAILED",
    GET_BOOKING_REQUEST: "WEB_EVENT/BOOKING_GET_REQUEST",
    GET_BOOKING_SUCCESS: "WEB_EVENT/BOOKING_GET_SUCCESS",

    FETCH_PAYMENTS_FAILED: "WEB_EVENT/PAYMENTS_FETCH_FAILED",
    FETCH_PAYMENTS_REQUEST: "WEB_EVENT/PAYMENTS_FETCH_REQUEST",
    FETCH_PAYMENTS_SUCCESS: "WEB_EVENT/PAYMENTS_FETCH_SUCCESS",

    STORE_FAILED: "WEB_EVENT/BOOKING_STORE_FAILED",
    STORE_REQUEST: "WEB_EVENT/BOOKING_STORE_REQUEST",
    STORE_SUCCESS: "WEB_EVENT/BOOKING_STORE_SUCCESS",

    CHECKOUT_FAILED: "WEB_EVENT/CHECKOUT_FAILED",
    CHECKOUT_REQUEST: "WEB_EVENT/CHECKOUT_REQUEST",
    CHECKOUT_SUCCESS: "WEB_EVENT/CHECKOUT_SUCCESS",

    FETCH_SUCCESS,
    FETCH_FAILED: "WEB_EVENT/FETCH_FAILED",
    FETCH_REQUEST: "WEB_EVENT/FETCH_REQUEST",

    GET_FAILED: "WEB_EVENT/GET_FAILED",
    GET_REQUEST: "WEB_EVENT/GET_REQUEST",
    GET_SUCCESS: "WEB_EVENT/GET_SUCCESS",

    FILTERS_FAILED: "WEB_EVENT/FILTERS_FAILED",
    FILTERS_REQUEST: "WEB_EVENT/FILTERS_REQUEST",
    FILTERS_SUCCESS,

    SETTINGS_FAILED,
    SETTINGS_REQUEST,
    SETTINGS_SUCCESS,

    SCROLL,

    TRANSLATE_FAILED: "WEB_EVENT/TRANSLATE_FAILED",
    TRANSLATE_REQUEST: "WEB_EVENT/TRANSLATE_REQUEST",
    TRANSLATE_SUCCESS: "WEB_EVENT/TRANSLATE_SUCCESS",

    GET_AVAILABILITY_FAILED: "AVAILABILITY/GET_FAILED",
    GET_AVAILABILITY_REQUEST: "AVAILABILITY/GET_REQUEST",
    GET_AVAILABILITY_SUCCESS,

    AVAILABILITY_UPDATE,
    TABLE_UPDATE,
};

export default types;
