import TicketModal from "./modals/TicketModal";
import TermsModal from "./modals/TermsModal";
import EventDetailsModal from "./modals/EventDetailsModal";
import UpsaleImageModal from "./modals/UpsaleImageModal";

import { registerModals } from "modals";
import { registerPublicRoutings } from "rootRouting";
import { IConfig, routingType } from "types/configTypes";
import AsyncComponent from "ui/AsyncComponent";

export const url = "/tickets";
export const uuid = window.location.pathname.match(
    /\/tickets\/([A-Za-z0-9-]+)/
)?.[1];

const getConfig = (settings: any) => {
    const config: IConfig = {
        permissions: {
            create: true,
            delete: true,
            read: true,
            update: true,
        },
        routing: [],
        url,
    };

    let routing: routingType = [];

    registerModals({
        TicketModal,
        TermsModal,
        UpsaleImageModal,
        EventDetailsModal,
    });
    routing.push(
        {
            path: `/`,
            element: <AsyncComponent path="WebEvent/pages/WebEventIndexPage" />,
        },
        {
            path: `/privacy-policy`,
            element: (
                <AsyncComponent path="WebEvent/pages/WebEventPrivacyPolicyPage" />
            ),
        },
        {
            path: `/terms-and-conditions`,
            element: (
                <AsyncComponent path="WebEvent/pages/WebEventTermsAndConditionsPage" />
            ),
        },
        {
            path: `/event/:id`,
            element: (
                <AsyncComponent
                    path={
                        settings["general-event_details_presentation"] ===
                        "PAGE"
                            ? "WebEvent/pages/WebEventDetailsPage"
                            : "WebEvent/pages/WebEventIndexPage"
                    }
                />
            ),
        },
        // {
        //     path: `/event/:id/arrangement`,
        //     element: (
        //         <AsyncComponent path="WebEvent/pages/WebEventArrangementPage" />
        //     ),
        // },
        // {
        //     path: `/event/:id/group-tickets`,
        //     element: (
        //         <AsyncComponent path="WebEvent/pages/WebEventGroupTicketsPage" />
        //     ),
        // },
        {
            path: `/basket`,
            element: (
                <AsyncComponent path="WebEvent/pages/WebEventBasketPage" />
            ),
        },
        {
            path: `/checkout`,
            element: (
                <AsyncComponent path="WebEvent/pages/WebEventCheckoutPage" />
            ),
        },
        {
            path: `/thank-you`,
            element: (
                <AsyncComponent path="WebEvent/pages/WebEventThankYouPage" />
            ),
        },
        {
            path: `/checkout-failed`,
            element: (
                <AsyncComponent path="WebEvent/pages/WebEventFailedPage" />
            ),
        }
    );
    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerPublicRoutings(routing);
    }

    return config;
};

export default getConfig;
