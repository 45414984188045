import { Box, SxProps } from "@mui/material";
import { FC, ReactNode } from "react";

interface IPageWrapper {
    children: ReactNode;
    sx?: SxProps;
}
const PageWrapper: FC<IPageWrapper> = ({ children, sx }) => {
    return (
        <Box sx={{ maxWidth: 1440, margin: "0 auto", pb: "60px", width: "100%", ...sx }}>
            {children}
        </Box>
    );
};

export default PageWrapper;
