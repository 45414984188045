import { Box, CircularProgress } from "@mui/material";
import { FC } from "react";
import { useSelector } from "react-redux";

interface IWebEventLoader {}
const WebEventLoader: FC<IWebEventLoader> = () => {
    const buttonBackground = useSelector(
        (state: any) =>
            state.web.settings.location_settings["general-button_background"]
    );
    return (
        <Box
            sx={{
                alignItems: "center",
                display: "flex",
                height: "100%",
                justifyContent: "center",
                left: 0,
                top: 0,
                width: "100%",
                zIndex: 100,
            }}
        >
            <CircularProgress sx={{ color: buttonBackground }} />
        </Box>
    );
};

export default WebEventLoader;
